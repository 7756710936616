.columns {
  display: flex;
  flex-wrap: wrap;
  padding: 0 calc(var(--pageMarginTotal) - (var(--gutter) / 2));
  width: 100%;

  &.no-gutters {
    width: 100%;
    margin-left: 0;

    .col {
      padding: 0;
    }
  }

  @media all and (max-width: $breakSmall) {
    row-gap: 16px;
  }

  .columns {
    @media all and (max-width: $breakSmall) {
      row-gap: 16px;
    }
  }
}

.col {
  padding: 0 calc(var(--gutter) / 2);
  position: relative;

  >.columns {
    padding: 0;
    width: calc(100% + var(--gutter));
    margin-left: calc((var(--gutter) / 2) * -1);
  }
}

.col:not([class*="col-"]) {
  flex: 1;

  @media all and (max-width: $breakSmall) {
    flex: auto;
    width: 100%;
  }
}

.col-1 {
  width: 8.333%;
}

.col-2 {
  width: 16.666%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.333%;
}

.col-5 {
  width: 41.666%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.333%;
}

.col-8 {
  width: 66.666%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.333%;
}

.col-11 {
  width: 91.666%;
}

.col-12 {
  width: 100%;
}

.offset-1 {
  margin-left: 8.333%;
}

.offset-2 {
  margin-left: 16.666%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333%;
}

.offset-5 {
  margin-left: 41.666%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333%;
}

.offset-8 {
  margin-left: 66.666%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333%;
}

.offset-11 {
  margin-left: 91.666%;
}

.tablet-lndscp-offset-0 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 0;
  }
}

.tablet-lndscp-offset-1 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 8.333%;
  }
}

.tablet-lndscp-offset-2 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 16.666%;
  }
}

.tablet-lndscp-offset-3 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 25%;
  }
}

.tablet-lndscp-offset-4 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 33.333%;
  }
}

.tablet-lndscp-offset-5 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 41.666%;
  }
}

.tablet-lndscp-offset-6 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 50%;
  }
}

.tablet-lndscp-offset-7 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 58.333%;
  }
}

.tablet-lndscp-offset-8 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 66.666%;
  }
}

.tablet-lndscp-offset-9 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 75%;
  }
}

.tablet-lndscp-offset-10 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 83.333%;
  }
}

.tablet-lndscp-offset-11 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 91.666%;
  }
}

.col-tablet-lndscp-1 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 8.333%;
  }
}

.col-tablet-lndscp-2 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 16.666%;
  }
}

.col-tablet-lndscp-3 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 25%;
  }
}

.col-tablet-lndscp-4 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 33.333%;
  }
}

.col-tablet-lndscp-5 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 41.666%;
  }
}

.col-tablet-lndscp-6 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 50%;
  }
}

.col-tablet-lndscp-7 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 58.333%;
  }
}

.col-tablet-lndscp-8 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 66.666%;
  }
}

.col-tablet-lndscp-9 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 75%;
  }
}

.col-tablet-lndscp-10 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 83.333%;
  }
}

.col-tablet-lndscp-11 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 91.666%;
  }
}

.col-tablet-lndscp-12 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 100%;
  }
}

.tablet-offset-0 {
  @media all and (max-width: $breakTablet) {
    margin-left: 0;
  }
}

.tablet-offset-1 {
  @media all and (max-width: $breakTablet) {
    margin-left: 8.333%;
  }
}

.tablet-offset-2 {
  @media all and (max-width: $breakTablet) {
    margin-left: 16.666%;
  }
}

.tablet-offset-3 {
  @media all and (max-width: $breakTablet) {
    margin-left: 25%;
  }
}

.tablet-offset-4 {
  @media all and (max-width: $breakTablet) {
    margin-left: 33.333%;
  }
}

.tablet-offset-5 {
  @media all and (max-width: $breakTablet) {
    margin-left: 41.666%;
  }
}

.tablet-offset-6 {
  @media all and (max-width: $breakTablet) {
    margin-left: 50%;
  }
}

.tablet-offset-7 {
  @media all and (max-width: $breakTablet) {
    margin-left: 58.333%;
  }
}

.tablet-offset-8 {
  @media all and (max-width: $breakTablet) {
    margin-left: 66.666%;
  }
}

.tablet-offset-9 {
  @media all and (max-width: $breakTablet) {
    margin-left: 75%;
  }
}

.tablet-offset-10 {
  @media all and (max-width: $breakTablet) {
    margin-left: 83.333%;
  }
}

.tablet-offset-11 {
  @media all and (max-width: $breakTablet) {
    margin-left: 91.666%;
  }
}

.col-tablet-1 {
  @media all and (max-width: $breakTablet) {
    width: 8.333%;
  }
}

.col-tablet-2 {
  @media all and (max-width: $breakTablet) {
    width: 16.666%;
  }
}

.col-tablet-3 {
  @media all and (max-width: $breakTablet) {
    width: 25%;
  }
}

.col-tablet-4 {
  @media all and (max-width: $breakTablet) {
    width: 33.333%;
  }
}

.col-tablet-5 {
  @media all and (max-width: $breakTablet) {
    width: 41.666%;
  }
}

.col-tablet-6 {
  @media all and (max-width: $breakTablet) {
    width: 50%;
  }
}

.col-tablet-7 {
  @media all and (max-width: $breakTablet) {
    width: 58.333%;
  }
}

.col-tablet-8 {
  @media all and (max-width: $breakTablet) {
    width: 66.666%;
  }
}

.col-tablet-9 {
  @media all and (max-width: $breakTablet) {
    width: 75%;
  }
}

.col-tablet-10 {
  @media all and (max-width: $breakTablet) {
    width: 83.333%;
  }
}

.col-tablet-11 {
  @media all and (max-width: $breakTablet) {
    width: 91.666%;
  }
}

.col-tablet-12 {
  @media all and (max-width: $breakTablet) {
    width: 100%;
  }
}

.offset-1,
.offset-2,
.offset-3,
.offset-4,
.offset-5,
.offset-6,
.offset-7,
.offset-8,
.offset-9,
.offset-10,
.offset-11,
.tablet-lndscp-offset-0,
.tablet-lndscp-offset-1,
.tablet-lndscp-offset-2,
.tablet-lndscp-offset-3,
.tablet-lndscp-offset-4,
.tablet-lndscp-offset-5,
.tablet-lndscp-offset-6,
.tablet-lndscp-offset-7,
.tablet-lndscp-offset-8,
.tablet-lndscp-offset-9,
.tablet-lndscp-offset-10,
.tablet-lndscp-offset-11,
.tablet-offset-0,
.tablet-offset-1,
.tablet-offset-2,
.tablet-offset-3,
.tablet-offset-4,
.tablet-offset-5,
.tablet-offset-6,
.tablet-offset-7,
.tablet-offset-8,
.tablet-offset-9,
.tablet-offset-10,
.tablet-offset-11 {
  @media screen and (max-width: $breakSmall) {
    margin-left: 0;
  }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-tablet-lndscp-1,
.col-tablet-lndscp-2,
.col-tablet-lndscp-3,
.col-tablet-lndscp-4,
.col-tablet-lndscp-5,
.col-tablet-lndscp-6,
.col-tablet-lndscp-7,
.col-tablet-lndscp-8,
.col-tablet-lndscp-9,
.col-tablet-lndscp-10,
.col-tablet-lndscp-11,
.col-tablet-lndscp-12,
.col-tablet-1,
.col-tablet-2,
.col-tablet-3,
.col-tablet-4,
.col-tablet-5,
.col-tablet-6,
.col-tablet-7,
.col-tablet-8,
.col-tablet-9,
.col-tablet-10,
.col-tablet-11,
.col-tablet-12 {
  @media screen and (max-width: $breakSmall) {
    width: 100%;
  }
}

.col-mobile-1 {
  @media all and (max-width: $breakSmall) {
    width: 12.5%;
  }
}

.col-mobile-2 {
  @media all and (max-width: $breakSmall) {
    width: 25%;
  }
}

.col-mobile-3 {
  @media all and (max-width: $breakSmall) {
    width: 37.5%;
  }
}

.col-mobile-4 {
  @media all and (max-width: $breakSmall) {
    width: 50%;
  }
}

.col-mobile-5 {
  @media all and (max-width: $breakSmall) {
    width: 62.5%;
  }
}

.col-mobile-6 {
  @media all and (max-width: $breakSmall) {
    width: 75%;
  }
}

.col-mobile-7 {
  @media all and (max-width: $breakSmall) {
    width: 87.5%;
  }
}

.col-mobile-8 {
  @media all and (max-width: $breakSmall) {
    width: 100%;
  }
}

.mobile-offset-0 {
  @media all and (max-width: $breakSmall) {
    margin-left: 0;
  }
}

.mobile-offset-1 {
  @media all and (max-width: $breakSmall) {
    margin-left: 12.5%;
  }
}

.mobile-offset-2 {
  @media all and (max-width: $breakSmall) {
    margin-left: 25%;
  }
}

.mobile-offset-3 {
  @media all and (max-width: $breakSmall) {
    margin-left: 37.5%;
  }
}

.mobile-offset-4 {
  @media all and (max-width: $breakSmall) {
    margin-left: 50%;
  }
}

.mobile-offset-5 {
  @media all and (max-width: $breakSmall) {
    margin-left: 62.5%;
  }
}

.mobile-offset-6 {
  @media all and (max-width: $breakSmall) {
    margin-left: 75%;
  }
}

.mobile-offset-7 {
  @media all and (max-width: $breakSmall) {
    margin-left: 87.5%;
  }
}

.show-tablet {
  display: none;

  @media all and (max-width: $breakTablet) {
    display: flex;
  }
}

.hide-tablet {
  @media all and (max-width: $breakTablet) {
    display: none;
  }
}

.show-small {
  display: none;

  @media all and (max-width: $breakSmall) {
    display: flex;
  }
}

.hide-small {
  @media all and (max-width: $breakSmall) {
    display: none !important;
  }
}