/* Page Setup + Mixins */

$breakWide: 1648px;
$breakTabletLndscp: 1024px;
$breakTablet: 768px;
$breakSmall: 650px;

:root {
  --gutter: 16px;
  --pageMarginTotal: 24px;
  --pageMargin: calc(var(--pageMarginTotal) - (var(--gutter) / 2));
  --easing: cubic-bezier(0.45, 0, 0.55, 1);
  --easingOut: cubic-bezier(0.16, 1, 0.3, 1);
  --white: #ffffff;
  --secondary-20: #f2f0eb;
  --secondary-40: #e6e2de;
  --secondary-60: #a6a08c;
  --secondary-80: #332e34;
  --black: #1e1e1e;
  --ochre-20: #eab595;
  --ochre-40: #d8886c;
  --ochre-60: #ae7153;
  --ochre-80: #de5333;
  --ochre: #d23745;

  @media all and (max-width: $breakSmall) {
    --gutter: 8px;
    --pageMarginTotal: 16px;
  }
}

@import "reset";
@import "grid";

/* Colours */

.bg-white {
  background: var(--white);
}

.bg-secondary-20 {
  background: var(--secondary-20);
}

.bg-secondary-40 {
  background: var(--secondary-40);
}

.bg-secondary-60 {
  background: var(--secondary-60);
}

.bg-secondary-80 {
  background: var(--secondary-80);
}

.bg-black {
  background: var(--black);
}

.bg-ochre-20 {
  background: var(--ochre-20);
}

.bg-ochre-40 {
  background: var(--ochre-40);
}

.bg-ochre-60 {
  background: var(--ochre-60);
}

.bg-ochre-80 {
  background: var(--ochre-80);
}

.bg-ochre {
  background: var(--ochre);
}

.color-white {
  color: var(--white);
}

.color-secondary-20 {
  color: var(--secondary-20);
}

.color-secondary-40 {
  color: var(--secondary-40);
}

.color-secondary-60 {
  color: var(--secondary-60);
}

.color-secondary-80 {
  color: var(--secondary-80);
}

.color-black {
  color: var(--black);
}

.color-ochre-20 {
  color: var(--ochre-20);
}

.color-ochre-40 {
  color: var(--ochre-40);
}

.color-ochre-60 {
  color: var(--ochre-60);
}

.color-ochre-80 {
  color: var(--ochre-80);
}

.color-ochre {
  color: var(--ochre);
}

::selection {
  background: var(--ochre-80);
  color: var(--white);
}

.spacer-40 {
  height: 40px;
}

.spacer-80 {
  height: 80px;
}

/* Type */

@font-face {
  font-family: "Graphik Web";
  src: url('../fonts/Graphik-Extralight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url('../fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('../fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url('../fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('../fonts/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@mixin type-display {
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 84px;
  letter-spacing: -0.05em;

  @media all and (max-width: 950px) {
    font-size: 50px;
    line-height: 50px;
  }

  @media all and (max-width: $breakSmall) {
    font-size: 32px;
    line-height: 36px;
  }
}

@mixin type-heading-1 {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.04em;

  @media all and (max-width: $breakSmall) {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.05em;
  }
}

@mixin type-heading-2 {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.04em;

  @media all and (max-width: $breakSmall) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}

@mixin type-subheading-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;

  @media all and (max-width: $breakSmall) {
    font-size: 16px;
    line-height: 20px;
  }
}

@mixin type-subheading-2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.035em;

  @media all and (max-width: $breakSmall) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }
}

@mixin type-body {
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.03em;

  @media all and (max-width: $breakSmall) {
    font-size: 16px;
    line-height: 20px;
  }
}

h1,
.type-heading-1 {
  @include type-heading-1;
}

h2,
.type-display {
  @include type-display;

  @media all and (max-width: $breakSmall) {
    margin-bottom: 0;
  }
}

h3,
.type-heading-2 {
  @include type-heading-2;
}

h4,
.type-subheading-1 {
  @include type-subheading-1;
}

h5,
.type-subheading-2 {
  @include type-subheading-2;
}

h5+h1,
h5+span.type-heading-1 {
  margin-top: 8px;
  display: block;
}

.media-block+h5 {
  margin-top: 17px;
}

/* Grid Overlay */

.grid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .column-overlay {
    background: red;
    opacity: 0.1;
    height: 100vh;
  }
}

/* General Styles */

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Graphik Web", sans-serif;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include type-body;

  &.no-scroll {
    @media all and (max-width: $breakTablet) {
      overflow: hidden;
    }
  }
}

main {
  overflow: hidden;

  &.no-intro {
    margin-top: 74px;
  }

  &.no-intro-big {
    margin-top: 164px;

    @media all and (max-width: $breakSmall) {
      margin-top: 64px;
    }
  }
}

p {
  -webkit-font-smoothing: subpixel-antialiased;
}

a {
  text-decoration: none;
  color: currentColor;
}

p a {
  color: var(--ochre-80);

  &:hover {
    color: var(--ochre-40);
    text-decoration: underline;
  }
}

.card {
  padding: 20px;
  background: red;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 24px;
  z-index: 15;

  &.mobile-menu-open {
    position: fixed;
    background-color: var(--secondary-80);
    height: 100%;
    color: var(--white);
    z-index: 1000;
  }

  .mobile-menu-link-wrap {
    display: none;
    align-items: center;
    justify-content: flex-end;

    @media all and (max-width: $breakTablet) {
      display: flex;
    }

    .mobile-menu-link {
      display: grid;
      font-size: 20px;

      .icon {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      .icon-close {
        opacity: 0;
      }

      &.active {

        .icon-close {
          opacity: 1;
        }

        .icon-menu {
          opacity: 0;
        }
      }
    }
  }

  nav.no-hide {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.end {
      justify-content: flex-end;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        a {
          @include type-subheading-2;
          transition: opacity .2s var(--easingOut);

          &:hover {
            opacity: .6;
          }
        }
      }

      &.end {
        justify-content: flex-end;
      }
    }
  }

  nav:not(.no-hide) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.end {
      justify-content: flex-end;
    }

    @media all and (max-width: $breakTablet) {

      --headerHeight: 85px;
      position: fixed;
      top: var(--headerHeight);
      left: 0;
      width: 100%;
      height: calc(100% - var(--headerHeight));
      opacity: 0;
      visibility: hidden;
      z-index: 100;
      flex-direction: column;
      background-color: var(--secondary-80);
      align-items: flex-start;
      padding-bottom: 70px;

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    @media all and (max-width: $breakSmall) {
      --headerHeight: 67px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      @media all and (max-width: $breakTablet) {
        flex-direction: column;
        width: 100%;
      }

      li {
        margin-right: 12px;

        &.active>a {
          opacity: .6;
        }

        &:last-child {
          margin-right: 0;
        }

        @media all and (max-width: $breakTablet) {
          border-bottom: 1px solid #E6E2DE33;
          margin-right: 0;

          &:first-child {
            border-top: 1px solid #E6E2DE33;
          }
        }

        a {
          @include type-subheading-2;
          transition: opacity .2s var(--easingOut);

          @media all and (max-width: $breakTablet) {
            @include type-display;
            display: block;
            padding: 16px var(--pageMarginTotal);
          }

          &:hover {
            opacity: .6;
          }
        }
      }

      &.end {
        justify-content: flex-end;
      }
    }
  }

  &.light {
    color: var(--white);
  }
}

header.mobile-header {
  background-color: var(--white);
  color: var(--black);
  position: fixed;
  padding-top: 19px;
  padding-bottom: 18px;
  border-bottom: 1px solid #E6E2DE;
  z-index: 100;
  transform: translateY(-100%);
  transition: all .4s var(--easingOut);

  &.show {
    transform: translateY(0);
  }

  &.mobile-menu-open {
    display: none;
  }
}

.lang-switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0px 12px;
  flex-wrap: wrap;

  @media all and (max-width: $breakTablet) {
    position: relative;
    border-top: 1px solid #E6E2DE33;
    width: calc(100% - var(--pageMarginTotal) * 2);
    margin-left: var(--pageMarginTotal);
    padding-top: 16px;
    justify-content: flex-start;

    &::before {
      content: 'Language';
      @include type-subheading-2;
      opacity: .4;
      display: block;
      position: absolute;
      left: 0;
      bottom: calc(100% + 8px);
    }
  }

  a {
    @include type-subheading-2;

    @media all and (max-width: $breakTablet) {
      font-size: 16px;
    }

    &.active {
      opacity: .6;
    }
  }
}

.results-link {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity .4s ease, visibility .4s ease;

  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.header-logo {
  .header-logo-img {
    height: 59px;
    margin-left: 5px;

    path {
      fill: currentColor;
    }

    @media all and (max-width: 950px) {
      height: 40px;
      margin-left: 4px;
    }

    @media all and (max-width: $breakSmall) {
      height: 23px;
      margin-left: 2px;
    }
  }
}

header:not(.mobile-menu-open) {
  .header-logo {
    .header-logo-img {

      &.small {
        height: 13px;
        margin-left: 0;
      }

      &.mobile-small {
        @media all and (max-width: $breakSmall) {
          height: 13px;
          margin-left: 0;
        }
      }
    }
  }
}

// img.header-logo-img {
//   position: relative;
//   left: 8px;
//   top: 4px;
//   width: 296px;

//   @media all and (max-width: $breakSmall) {
//     position: initial;
//     left: 0;
//     width: 122px;
//   }

// }

i {
  font-style: normal;
  font-weight: 200;
}

p+p {
  margin-top: 1em;
}

footer {
  background: var(--secondary-80);
  color: var(--white);
  min-height: 100vh;
  min-height: 100dvh;
  padding: 20px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    color: var(--white);
  }

  .footer-bottom {
    margin-top: 60px;
  }

  &.offer-footer {
    padding-bottom: 120px;
  }

  p {
    @include type-subheading-2;
  }

  ul {
    @media all and (max-width: $breakSmall) {
      margin-top: 60px;
    }

    li {
      a {
        @include type-heading-1;
        position: relative;

        @media all and (max-width: $breakSmall) {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.04em;
        }

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: var(--white);
          opacity: 0.2;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 0;
          transition: 0.2s ease;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.page-header {
  min-height: 100vh;
  min-height: 100svh;
  position: relative;
  color: var(--white);

  .page-header-content {
    position: relative;
    z-index: 10;
    padding-top: 95px;

    @media all and (max-width: 950px) {
      padding-top: 77px;
    }

    @media all and (max-width: $breakSmall) {
      padding-top: 55px;
    }

    .transparent {
      opacity: .6;
    }

    h2 {
      max-width: 1200px;
    }
  }

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 5;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: .15;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }

  .page-header-bottom {
    position: absolute;
    z-index: 5;
    bottom: 32px;
    left: 0;
    width: 100%;
    max-width: 344px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    @include type-subheading-2;
    font-weight: 400;

    &.full-width {
      max-width: none;
      border-top: none;

      .page-header-block {
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        padding-top: 15px;
      }
    }

    &.with-offer {
      bottom: 142px;

      @media all and (max-width: $breakSmall) {
        bottom: 42px;
      }
    }
  }

  &.w-overlay {
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .2);
      background: linear-gradient(180deg, rgba(0, 0, 0, .2) 56.43%, rgba(0, 0, 0, 0.65) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.button {
  display: inline-block;
  background: var(--ochre-80);
  color: var(--white);
  padding: 10px 12px;
  border-radius: 50px;
  @include type-subheading-2;
  transition: 0.3s ease;

  &:hover {
    background: var(--ochre-40);
  }

  &.large {
    padding: 16px 24px;
    @include type-subheading-1;
    --flow-space: 16px;

    @media all and (max-width: $breakSmall) {
      padding: 8px 12px;
    }
  }

  &.white {
    background: var(--white);
    color: var(--secondary-80);

    &:hover {
      color: var(--white);
      background: var(--ochre-80);
    }
  }
}

// *+.button {
//   margin-top: 20px;
// }

section {
  padding: 24px 0 120px 0;

  @media all and (max-width: $breakSmall) {
    padding: 16px 0 40px 0;
  }

  &.border-top {
    border-top: 1px solid var(--secondary-40);
  }

  h2 {
    color: var(--secondary-80);
  }

  &.tips {
    padding: 24px 0 32px;
  }

  &.above {
    position: relative;
    z-index: 100;
  }
}

.tip-button {
  margin-top: 180px;
}

.arrow-cta {
  display: inline-flex;
  font-size: 16px;
  width: 32px;
  height: 32px;
  background: var(--ochre-80);
  color: var(--white);
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;

  &.wider-text {
    width: 110px;
    border-radius: 16px;
  }

  .explore-more {
    display: block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: all 0.3s var(--easing);
    z-index: 0;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      animation: rotate 7s linear infinite;
    }
  }

  &:hover {
    .explore-more {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

*+.arrow-cta {
  margin-top: 32px;

  @media all and (max-width: $breakSmall) {
    margin-top: 16px;
  }
}

.video-block {
  position: relative;

  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s var(--easing);
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .video {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s var(--easing);
    aspect-ratio: 16 / 9;

    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .controls {
    position: absolute;
    z-index: 10;
    bottom: 32px;
    left: var(--pageMarginTotal);
    transition: all 0.3s var(--easing);
  }

  &.playing {

    .thumbnail,
    .controls {
      opacity: 0;
      visibility: hidden;
    }

    .video {
      opacity: 1;
      visibility: visible;
    }
  }
}

.testimonials-title {
  @include type-heading-1;
  color: var(--secondary-60);
}

.testimonials {
  background: var(--secondary-20);
  padding: 24px 0 33px;
  position: relative;

  .flickity-slider {
    transform: none !important;
  }

  .testimonial {
    left: 0 !important;
    opacity: 0;
    transition: opacity 0.6s var(--easing);
    z-index: -1;
  }

  .testimonial.is-selected {
    opacity: 1;
    z-index: 0;
    transform: none !important;
  }

  .testimonial {
    width: 100%;
    min-height: 100%;

    .testimonial-text {
      @include type-heading-1;
    }

    .testimonial-spinner {
      padding-right: 20px;
      margin-top: 13px;
      width: 100%;
      padding-bottom: 100%;
      position: relative;

      @media all and (max-width: $breakSmall) {
        width: 170px;
        height: 170px;
        padding-bottom: 0;
        margin-top: 80px;
        margin-left: auto;
        margin-bottom: 40px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .testimonial-footer {
      margin-top: 45px;
      position: absolute;
      left: var(--pageMargin);
      bottom: 0;
      @include type-subheading-2;

      @media all and (max-width: $breakSmall) {
        margin-top: 0;
      }
    }

    &.is-selected {
      .testimonial-spinner {
        img {
          animation: rotate 14s linear infinite;
        }
      }
    }

    >.columns {
      .col {
        @media all and (max-width: $breakSmall) {
          margin-bottom: 30px;
        }

        &:last-child {
          @media all and (max-width: $breakSmall) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .progress {
    position: absolute;
    bottom: 32px;
    right: var(--pageMarginTotal);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    .progress-ring {
      transform: rotate(-90deg);
    }

    .progress-ring .progress-ring__circle {
      stroke-dasharray: 107;
      // stroke-dashoffset: 107;
    }
  }
}

.loader {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  .progress-ring {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg);
  }

  .progress-ring .progress-ring__circle {
    stroke-dasharray: 107;
    stroke-dashoffset: 107;
    animation: loader 1.6s infinite linear;
  }
}

@keyframes loader {
  to {
    stroke-dashoffset: -107;
  }
}

.logo-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  position: relative;

  @media all and (max-width: $breakSmall) {
    gap: 8px;
  }

  &::after {
    // Ugly fix to remove bottom item borders without clipping or overflow
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .logo-block {
    display: block;
    border-bottom: 1px solid var(--secondary-40);
    width: calc((100% - (16px * 2)) / 3);
    padding-bottom: 65px;
    position: relative;
    margin-bottom: 10px;

    @media all and (max-width: $breakSmall) {
      width: calc((100% - 8px) / 2);
    }

    .explore-more {
      display: block;
      width: 115px;
      height: 115px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      transition: transform 0.8s var(--easingOut);
      pointer-events: none;
      z-index: 5;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        animation: rotate 7s linear infinite;
      }
    }

    &:hover {
      .explore-more {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }

    p {
      color: var(--secondary-80);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.03em;
      margin-bottom: 10px;
    }

    .logo-block-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        max-width: 70%;
        max-height: 70px;
      }
    }
  }
}

.media-block {
  &.natural-height {
    height: auto;
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }
}

.questionnaire-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(222, 83, 51, 0.2);

  @media all and (max-width: $breakSmall) {
    top: auto;
    bottom: 80px;
  }

  .bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: var(--ochre-80);
  }
}

.your-answer-label {
  margin-left: 9px;
}

.questionnaire-main {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: calc(100vh - 74px);

  .show-while-loading {
    display: none;
  }

  &.loading {
    .show-while-loading {
      display: block;
    }

    .hide-while-loading {
      display: none;
    }
  }

  .answer-fields .show-while-loading {
    padding: 16px;
  }

  .questionnaire-footer .show-while-loading {
    padding-left: var(--pageMarginTotal);
  }
}

.questionnaire-header {
  border-bottom: 1px solid var(--secondary-40);
  padding: 12px 0;
  height: 45px;

  @media all and (max-width: $breakSmall) {
    display: none;
  }
}

.mobile-answer-column-label {
  border-bottom: 1px solid var(--secondary-40);
  padding: 16px;
}

.questionnaire-body {
  flex: 1;
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-bottom: 150px;
  height: calc(100% - 45px);

  @media all and (max-width: $breakSmall) {
    display: block;
    padding-bottom: 200px;
    height: 100%;
  }

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--secondary-40);

    @media all and (max-width: $breakSmall) {
      display: none;
    }
  }

  >.left,
  >.right {
    flex: 1;
    overflow-y: auto;

    @media all and (max-width: $breakSmall) {
      width: 100%;
    }
  }

  >.left {
    position: relative;

    @media all and (max-width: $breakSmall) {
      border-bottom: 1px solid var(--secondary-40);
      padding-bottom: 32px;
    }
  }

  >.right {
    height: calc(100vh - 250px);

    @media all and (max-width: $breakSmall) {
      height: calc(100vh - 300px);
      height: calc(100svh - 280px);
    }
  }

  .question-prompt {
    padding: 12px 75px 12px var(--pageMarginTotal);
  }
}

.clarification-button {
  display: none;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid var(--secondary-40);
  color: var(--black);
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: absolute;
  top: 16px;
  right: 16px;

  &:hover {
    background: var(--ochre-80);
    border-color: var(--ochre-80);
    color: white;
  }

  &.show {
    display: flex;
  }
}

.questionnaire-footer {
  display: flex;
  align-items: flex-end;
  position: relative;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  background: white;

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--secondary-40);

    @media all and (max-width: $breakSmall) {
      display: none;
    }
  }

  .left,
  .right {
    flex: 1;
    padding-bottom: 24px;
  }

  .right {
    position: relative;

    @media all and (max-width: $breakSmall) {
      text-align: right;
      padding-right: 16px;
    }
  }

  .middle {
    display: none;

    @media all and (max-width: $breakSmall) {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .previous-question {
    display: none;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid var(--secondary-40);
    color: var(--ochre-80);
    margin: 0 0 0 var(--pageMarginTotal);
    // display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    &:hover {
      background: var(--ochre-80);
      border-color: var(--ochre-80);
      color: white;
    }

    &.have-prev-question {
      display: flex;
    }
  }

  .next-question {
    margin: 0 0 0 16px;
  }

  .mandatory-alert {
    padding: 16px;
    border: 1px solid var(--ochre-80);
    border-radius: 40px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%);
    transition: .6s var(--easingOut);
    position: absolute;
    left: 16px;
    width: calc(100% - 32px);
    bottom: calc(100% + 16px);
    background: white;

    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

fieldset {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}

.type-radio,
.type-boolean {
  label {
    display: block;
    padding: 12px 16px;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include type-heading-1;
    border-bottom: 1px solid var(--secondary-40);

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
      height: 20px;
      width: 20px;
      background-color: var(--white);
      border: 1px solid var(--secondary-40);
      color: var(--ochre-80);
      border-radius: 50%;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        font-size: 8px;
        height: 8px;
        transition: all 0.2s var(--easingOut);
      }
    }

    &:hover,
    &.selected {
      background: var(--ochre-80);
      color: var(--white);
    }

    input:checked~.checkmark {
      border-color: var(--white);

      .icon {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  .extra-input {
    display: none;

    input[type=text] {
      width: 100%;
      @include type-heading-1;
      color: var(--ochre-80);
      border: 0;
      outline: 0;
      appearance: none;
      border-radius: 0;
      border-bottom: 1px solid var(--secondary-40);
      padding: 12px 16px;

      &::placeholder {
        color: var(--ochre-80);
        opacity: .5;
      }
    }
  }

  label.selected+.extra-input {
    display: block;
  }
}

.answer-title {
  @include type-heading-1;
  color: var(--ochre-80);
  padding: 12px 16px;
  border-bottom: 1px solid var(--secondary-40);
  width: 100%;
}

.footprint-comparison {
  position: relative;
  z-index: 75;
  pointer-events: none;
}

.your-footprint {
  position: relative;
  pointer-events: none;

  &:before {
    content: '';
    display: block;
    width: calc(100% - (var(--pageMarginTotal) * 2));
    height: 1px;
    background-color: var(--secondary-40);
    position: absolute;
    z-index: 20;
    bottom: 0;
    left: var(--pageMarginTotal);
    pointer-events: none;
  }
}

.results-module {
  margin-top: -300px;
  position: relative;
  z-index: 50;
  padding: 0;
  margin-bottom: -4px;

  &.no-circles {
    margin-top: 0;
    padding-top: 40px;
    padding-bottom: 100px;
  }

  canvas {
    width: 100% !important;
    height: 100% !important;
  }

  @media all and (max-width: $breakTabletLndscp) {
    margin-top: -100px;
  }

  @media all and (max-width: $breakSmall) {
    margin-top: -100px;
  }
}

.custom-select {
  position: relative;
}

.custom-select select {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.select-selected {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include type-heading-1;
  border-bottom: 1px solid var(--secondary-40);
}

.select-selected .dropdown-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 32px;
  height: 32px;
  border: 1px solid var(--secondary-40);
  border-radius: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-selected.select-arrow-active .dropdown-arrow {
  transform: translateY(-50%) rotate(-180deg);
}

.select-selected.select-arrow-active {
  color: var(--secondary-80);
}

.select-items div,
.select-selected {
  color: var(--ochre-80);
  padding: 12px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent var(--secondary-40) transparent;
  cursor: pointer;
}

.select-items {
  div {
    color: var(--secondary-80);
    @include type-heading-1;
  }
}

.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(222, 83, 51, .05);
  color: var(--ochre-80);
}

.custom-slider-block {
  color: var(--ochre-80);
  padding: 12px 16px;
  border-bottom: 1px solid var(--secondary-40);
  width: 100%;

  span.highlight {
    opacity: .5;
  }

  // input[type=range] {
  //   accent-color: var(--ochre-80);
  //   -webkit-appearance: none;
  //   appearance: none;
  //   width: 100%;
  //   height: 8px;
  //   background: #FEDCD5;
  //   outline: none;
  //   transition: opacity .2s;
  //   border-radius: 20px;

  //   &::-webkit-slider-thumb {
  //     -webkit-appearance: none;
  //     appearance: none;
  //     width: 24px;
  //     height: 24px;
  //     border-radius: 24px;
  //     background: #FFF;
  //     cursor: pointer;
  //     box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  //   }

  //   &::-moz-range-thumb {
  //     width: 24px;
  //     height: 24px;
  //     border-radius: 24px;
  //     background: #FFF;
  //     -moz-appearance: none;
  //     appearance: none;
  //     cursor: pointer;
  //     box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  //   }
  // }  
  // $height: 24px;
  // $thumb-height: 24px;
  // $track-height: 8px;

  // // colours
  // $upper-color: #FEDCD5;
  // $lower-color: var(--ochre-80);
  // $thumb-color: #fff;
  // $thumb-hover-color: #fff;

  // $upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
  // $lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

  // // Webkit cannot style progress so we fake it with a long shadow
  // // on the thumb element
  // @function webkit-slider-thumb-shadow($i: 1) {
  //   $val: #{$i}px 0 0 -#{calc($thumb-height - $track-height) / 2} #{$upper-color};

  //   @if $i ==1 {
  //     @for $k from 2 through 1000 {
  //       $val: #{$val}, webkit-slider-thumb-shadow($k);
  //     }
  //   }

  //   @return $val;
  // }

  // input[type=range] {
  //   display: block;
  //   -webkit-appearance: none;
  //   appearance: none;
  //   width: 100%;
  //   margin: 0;
  //   // height: $height;
  //   height: 32px;
  //   padding: 0 4px;
  //   overflow: hidden;
  //   cursor: pointer;

  //   &:focus {
  //     outline: none;
  //   }
  // }

  // // Webkit
  // input[type=range]::-webkit-slider-runnable-track {
  //   width: 100%;
  //   height: $height;
  //   background: $upper-background;
  //   border-radius: 24px;
  // }

  // input[type=range]::-webkit-slider-thumb {
  //   position: relative;
  //   appearance: none;
  //   -webkit-appearance: none;
  //   height: $thumb-height;
  //   width: $thumb-height;
  //   background: $thumb-color;
  //   border-radius: 100%;
  //   border: 0;
  //   top: 50%;
  //   margin-top: calc(-#{$thumb-height}/2);
  //   box-shadow: webkit-slider-thumb-shadow();
  //   border: 1px solid rgba(253, 253, 253, 1);
  //   // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  //   transition: background-color 150ms;
  //   filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));


  //   &:after {
  //     content: "etst";
  //   }
  // }

  // // Firefox
  // input[type=range]::-moz-range-track,
  // input[type=range]::-moz-range-progress {
  //   width: 100%;
  //   height: 32px;
  //   background: $upper-background;
  // }

  // input[type=range]::-moz-range-progress {
  //   background: $lower-background;
  // }

  // input[type=range]::-moz-range-thumb {
  //   appearance: none;
  //   margin: 0;
  //   height: $thumb-height;
  //   width: $thumb-height;
  //   background: $thumb-color;
  //   border-radius: 100%;
  //   // border: 0;
  //   border: 1px solid $lower-color;
  //   transition: background-color 150ms;
  // }

  // // Internet Exploder
  // input[type=range]::-ms-track {
  //   width: 100%;
  //   height: $height;
  //   border: 0;
  //   // color needed to hide track marks
  //   color: transparent;
  //   background: transparent;
  // }

  // input[type=range]::-ms-fill-lower {
  //   background: $lower-background;
  // }

  // input[type=range]::-ms-fill-upper {
  //   background: $upper-background;
  // }

  // input[type=range]::-ms-thumb {
  //   appearance: none;
  //   height: $thumb-height;
  //   width: $thumb-height;
  //   background: $thumb-color;
  //   border-radius: 100%;
  //   border: 0;
  //   transition: background-color 150ms;
  //   // IE Edge thinks it can support -webkit prefixes
  //   top: 0;
  //   margin: 0;
  //   box-shadow: none;
  // }

  // input[type=range]:hover,
  // input[type=range]:focus {

  //   &::-webkit-slider-thumb {
  //     background-color: $thumb-hover-color;
  //   }

  //   &::-moz-range-thumb {
  //     background-color: $thumb-hover-color;
  //   }

  //   &::-ms-thumb {
  //     background-color: $thumb-hover-color;
  //   }
  // }

  --thumb-height: 24px;
  --thumb-width: 24px;
  --thumb-bg: #ffffff;
  --track-height: 8px;
  --track-bg: rgba(222, 83, 51, .2);
  --track-fill: rgba(222, 83, 51, 1);

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    padding: 8px 0;
    --percFilled: 0%;
  }

  input[type="range"]:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: var(--track-bg);
    background: linear-gradient(to right,
        var(--track-fill),
        var(--track-fill) var(--percFilled),
        var(--track-bg) var(--percFilled),
        var(--track-bg));
    border-radius: var(--track-height);
    height: var(--track-height);
  }

  /* slider thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    margin-top: -8px;
    /* Centers thumb on the track */

    /*custom styles*/
    background-color: var(--thumb-bg);
    height: var(--thumb-height);
    width: var(--thumb-width);
    border-radius: var(--thumb-height);
    border: 1px solid #FDFDFD;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, .2));
    // box-shadow: calc((50vh + (var(--thumb-width) / 2)) * -1) 0 0 50vh var(--track-fill);
  }

  /******** Firefox styles ********/
  /* slider track */
  input[type="range"]::-moz-range-track {
    background-color: var(--track-bg);
    background: linear-gradient(to right,
        var(--track-fill),
        var(--track-fill) var(--percFilled),
        var(--track-bg) var(--percFilled),
        var(--track-bg));
    border-radius: var(--track-height);
    height: var(--track-height);
  }

  /* slider thumb */
  input[type="range"]::-moz-range-thumb {
    border: none;
    /*Removes extra border that FF applies*/
    border-radius: 0;
    /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: var(--thumb-bg);
    height: var(--thumb-height);
    width: var(--thumb-width);
    border-radius: var(--thumb-height);
    border: 1px solid #FDFDFD;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, .2));
  }
}

.custom-slider-block-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  .right {
    text-align: right;
    min-width: 140px;
  }

  .slider-value {
    &.unit-minutes {
      &:after {
        content: " mins";
      }
    }

    &.unit-eur {
      &:after {
        content: " EUR";
      }
    }
  }
}

.autocomplete-wrap {
  input {
    width: 100%;
    @include type-heading-1;
    color: var(--ochre-80);
    border: 0;
    outline: 0;
    appearance: none;
    border-radius: 0;
    border-bottom: 1px solid var(--secondary-40);
    padding: 12px 16px;

    &::placeholder {
      color: var(--ochre-80);
      opacity: .5;
    }
  }

  .autoComplete_wrapper {
    >ul {
      >li {
        @include type-heading-1;
        border-bottom: 1px solid var(--secondary-40);
        padding: 12px 16px;
        color: #EB9885;
        cursor: pointer;

        mark {
          background-color: transparent;
          color: var(--ochre-80);
        }

        &:hover {
          background-color: rgba(222, 83, 51, 0.05);
        }
      }
    }
  }

  &.requires-input {
    .autoComplete_wrapper {
      position: relative;

      >ul {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: white;
        z-index: 20;
      }
    }
  }
}

.refine-cards {
  margin-top: 130px;

  .refine-card {
    border: 1px solid var(--secondary-40);
    border-radius: 8px;
    padding: 16px;
    display: block;
    margin-bottom: var(--gutter);
    transition: all .4s var(--easingOut);

    .label {
      @include type-subheading-1;
      display: block;
      margin-bottom: 36px;
    }

    .amount {
      @include type-subheading-2;
      color: var(--ochre-80);
      display: block;
      margin-bottom: 40px;

      .number {
        @include type-heading-1;
        margin-right: 8px;

        span {
          font-weight: 100;
        }
      }
    }

    .questions-completed {
      @include type-subheading-2;
      display: block;
      margin-bottom: 12px;
      color: var(--secondary-60);

      .circle-icon {
        display: inline-flex;
        width: 20px;
        height: 20px;
        font-size: 10px;
        background: white;
        color: var(--ochre-80);
        border-radius: 100%;
        margin-right: 8px;
        align-items: center;
        justify-content: center;
      }

      .current {
        color: black;
      }
    }

    .progress-bar {
      display: block;
      position: relative;
      height: 4px;
      border-radius: 4px;
      overflow: hidden;
      background: rgba(222, 83, 51, .2);

      .bar {
        background: var(--ochre-80);
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
      }
    }

    &.completed {
      background: var(--ochre-80);
      border-color: var(--ochre-80);
      color: white;
      cursor: default;
      pointer-events: none;

      .amount,
      .questions-completed {
        color: white;
      }

      .progress-bar {
        background: rgba(255, 255, 255, .2);

        .bar {
          background: white;
        }
      }
    }

    &:hover {
      border-color: var(--ochre-40);
    }
  }
}

.offers,
.sme-offers {
  margin-top: 50px;
}

.offer+.offer {
  margin-top: 16px;
}

.offer {
  // display: flex;
  border: 1px solid var(--secondary-40);
  border-radius: 8px;
  overflow: hidden;
  display: none;

  &.show {
    display: flex;

    @media all and (max-width: $breakSmall) {
      display: block;
    }
  }

  .left,
  .right {
    flex: 1;
  }

  .left {
    position: relative;

    @media all and (max-width: $breakSmall) {
      height: 0;
      padding-bottom: 62.7%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .right {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .bottom {
      margin-top: 170px;
      border-top: 1px solid var(--secondary-40);
      padding-top: 15px;
      position: relative;

      @media all and (max-width: $breakSmall) {
        margin-top: 24px;
      }

      .amount {
        @include type-subheading-2;
        display: block;
        margin-top: 8px;
        margin-bottom: 40px;

        @media all and (max-width: $breakSmall) {
          margin-bottom: 20px;
        }

        .number {
          @include type-heading-1;
          margin-right: 8px;

          span {
            font-weight: 100;
          }
        }
      }

      .offer-logo {
        position: absolute;
        bottom: 0;
        right: 0;

        @media all and (max-width: $breakSmall) {
          max-width: calc(100% - 150px);
        }

        img {
          max-height: 40px;

          @media all and (max-width: $breakSmall) {
            max-height: 30px;
          }
        }
      }
    }
  }
}

.amount {
  .number {
    span {
      font-weight: 100;
    }
  }
}

.checklist {
  ul {
    border-top: 1px solid var(--secondary-40);
    margin-top: 15px;
    padding-top: 15px;
    width: 100%;

    li {
      @include type-subheading-2;
      color: var(--ochre-80);
      margin-bottom: 16px;
      position: relative;
      padding-left: 30px;

      &::before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: url('../img/checklist.svg') no-repeat center center;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.signature {
  border-bottom: 1px solid var(--secondary-40);
  margin-bottom: 16px;
}

.circles-toggles {
  position: relative;
  z-index: 60;

  .toggle-links {
    display: flex;
    gap: 24px;
    transform: translateY(1px);

    span,
    a {
      @include type-subheading-2;
      padding-bottom: 12px;
      opacity: .6;
      pointer-events: all;

      &.active {
        opacity: 1;
        border-bottom: 1px solid var(--secondary-80);
      }

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

.comparisons {
  margin-top: 12px;

  .comparison+.comparison {
    margin-top: 18px;
  }
}

.total-result {
  display: block;
  color: var(--ochre-80);
  margin-top: 15px;
  @include type-heading-1;
}

.form-wrap {
  margin: 80px 0;

  &.no-margin {
    margin: 0;
  }

  form {

    .check-wrap-outer {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .check-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      flex-basis: 50%;

      input {
        margin-right: 8px;
      }

      label {
        user-select: none;
        position: relative;
        top: 4px;
      }
    }

    .form-field {
      margin-bottom: 24px;
      border-bottom: 1px solid var(--secondary-40);
      padding-bottom: 24px;
      position: relative;

      &.no-border {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 32px;
      }

      span.number {
        position: absolute;
        right: 100%;
        margin-right: var(--gutter);
        min-width: calc((100vw - (var(--gutter) * 10) - (var(--pageMarginTotal) * 2)) / 12);
        top: 0;

        @media all and (max-width: $breakSmall) {
          position: static;
          margin-bottom: 8px;
          display: block;
        }
      }

      label {
        display: block;
        max-width: 530px;
        margin-bottom: 9px;
        @include type-subheading-1;
      }

      input+input {
        margin-top: 12px;
      }
    }

    textarea,
    input[type=text],
    input[type=email] {
      display: block;
      width: 100%;
      background: var(--secondary-20);
      border: 0;
      outline: 0;
      appearance: none;
      border-radius: 0;
      padding: 16px;
      @include type-subheading-1;

      &::placeholder {
        color: var(--secondary-80);
        opacity: .5;
      }
    }

    textarea {
      min-height: 260px;
      resize: none;

      @media all and (max-width: $breakSmall) {
        min-height: 150px;
      }
    }

    input[type=submit].button {
      border: none;
      outline: none;
      appearance: none;
      cursor: pointer;
    }
  }

  .form-status {
    font-weight: 500;

    &.active {
      margin-top: 40px;
    }
  }
}

.hide {
  display: none;
}

.redeem-offer {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--ochre-80);
  color: white;
  z-index: 300;
  padding: 16px 0 32px 0;
  transition: padding .3s var(--easingOut);

  &:hover {
    padding: 32px 0;

    .icon {
      top: 32px;
    }
  }

  .icon {
    transition: top .3s var(--easingOut);
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .offer-headline {
    opacity: .7;
  }

  p+p {
    margin-top: 0;
  }
}

.german-de-modal,
.offer-modal,
.clarification-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 350;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: all .4s var(--easingOut);

  &.show {
    pointer-events: all;
    opacity: 1;
    visibility: visible;
  }

  .modal-bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .8;
    z-index: 1;
  }

  .modal-content {
    display: block;
    background: white;
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 69px 24px 24px;
    width: 95%;
    max-width: 528px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;

    &.questionnaire-modal {
      text-align: left;

      >div:nth-child(2)>*+* {
        margin-top: 16px;
      }

      *+.button {
        margin-top: 0;
      }

      .form-wrap form .form-field.no-border {
        margin-bottom: 24px;
      }

      .form-wrap form .form-field label {
        @include type-subheading-2;
      }
    }

    .modal-content-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 16px;
      text-align: left;
      border-bottom: 1px solid var(--secondary-20);

      .modal-content-title {
        @include type-subheading-2;
      }
    }

    .modal-content-content {
      max-height: calc(90vh - 100px);
      overflow-y: scroll;
    }

    p+p {
      margin-top: 40px;
    }

    .qr-code {
      width: 200px;
      height: 200px;
      margin: 40px auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .close-modal {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 50;
      font-size: 16px;
      height: 16px;
    }
  }
}

.questionnaire-progress-numbers {
  color: var(--secondary-60);

  .current-question-number {
    color: var(--black);
  }

}

.suggest-brands {
  background: rgba(222, 83, 51, .2);
  color: var(--secondary-80);
  padding: 24px 0 120px 0;
  margin-top: 120px;
  margin-bottom: -120px;

  @media all and (max-width: $breakSmall) {
    padding: 16px 0 40px 0;
    margin-top: 40px;
    margin-bottom: -40px;
  }

  .form-wrap {
    margin-top: 40px;
  }

  form {
    input[type=text] {
      background: transparent;
      @include type-heading-2;
      padding: 8px 0;
      border-bottom: 1px solid rgba(51, 46, 52, .2);

      &:focus {
        border-bottom: 1px solid rgba(51, 46, 52, 1);
      }
    }

    input[type=submit] {
      margin-top: 0;
    }
  }
}

aside.de-contact-popup {
  display: none;

  &.show {
    display: block;
  }

}

figure.portrait-image {
  margin-bottom: 12px;

  img {
    width: 100%;
  }
}

figcaption {
  @include type-subheading-2;
}

.testimonial-spinner-hero {

  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);

  @media all and (max-width: $breakSmall) {
    width: 31%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 62%;
  }

  &.testimonial-spinner {
    img {
      animation: rotate 14s linear infinite;
    }
  }
}